import React, { useState } from 'react';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import LinkWithQuery from '../components/LinkWithQuery';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NewsLetterPage = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;
	const [formState, setFormState] = useState('');

	const handleSubmit = (event) => {
		event.preventDefault();
		setFormState('processing');

		const lead = Object.assign({}, window.sessionLayer.leadHandler.lead.lead);
		lead.Dynamic_Marketing_Comment__c = JSON.stringify(lead);
		lead.Lead_URL__c = document.location.href;
		lead.Email = event.target.email.value;
		lead.Express_written_consent__c = 'consent provided';

		window.sessionLayer.leadHandler
			.submitToLeadHandler(lead)
			.then(function () {
				setFormState('success');
			})
			.catch(function (error) {
				console.error(error);
				setFormState('error');
			});
	};

	return (
		<Layout>
			<Seo title="Newsletter - hear.com" />
			<div className="o-wrapper o-wrapper--small">
				<div className="o-row-2p5" style={{paddingTop: '50px'}}>
				<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="c-newsletter-hero">
					<div className="c-newsletter-img">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/newsletter/main.png"
							alt="man sitting on couch and smiling"
							loading="lazy"
						/>
					</div>
					<article className="c-newsletter-intro">
						<h1 className="c-newsletter-intro__headline">Join our list</h1>
						<p className="c-newsletter-intro__copy">
							Join our exclusive community to receive information about the newest product innovations via email.
						</p>
						<div className={`c-newsletter ${formState}`}>
							<form className="c-newsletter__form" onSubmit={handleSubmit}>
								<input className="c-newsletter__input" id="email" type="email" required placeholder="Email" />
								<button className="c-newsletter__btn" type="submit">
									Sign Up Now
								</button>
							</form>
							<p className="c-newsletter__processing">Processing...</p>
							<p className="c-newsletter__success">Success! You’ll start receiving the latest from hear.com soon.</p>
							<p className="c-newsletter__error">There was an issue adding your email, please try again later</p>
							<p className="c-newsletter__disclaimer">
								By clicking on the "Sign Up Now" button above, you consent, acknowledge and agree to the following: Our
								<LinkWithQuery to="/privacy-policy/">Privacy Policy</LinkWithQuery>,
								<LinkWithQuery to="/terms-and-conditions/">Terms and Conditions</LinkWithQuery> and to receive important notices and other
								communications electronically, including email. We take your privacy seriously, and you may opt out of
								receiving future messages from hear.com at any time.
							</p>
						</div>
					</article>
				</div>
				<div className="c-newsletter-icons__grid">
					<div className="c-newsletter-icons__col">
						<img
							className="c-newsletter-icon"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/newsletter/caller-ico.svg"
							alt=""
							loading="lazy"
						/>
						<p className="c-newsletter-icons__copy">Get expert advice & insight from hearing care specialists</p>
					</div>
					<div className="c-newsletter-icons__divider"></div>
					<div className="c-newsletter-icons__col second">
						<img
							className="c-newsletter-icon paper"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/newsletter/paper-ico.svg"
							alt=""
							loading="lazy"
						/>
						<p className="c-newsletter-icons__copy">
							Read the latest
							<br /> product news
						</p>
					</div>
					<div className="c-newsletter-icons__divider"></div>
					<div className="c-newsletter-icons__col">
						<img
							className="c-newsletter-icon idea"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/newsletter/idea-ico.svg"
							alt=""
							loading="lazy"
						/>
						<p className="c-newsletter-icons__copy">
							Learn about modern hearing solutions, get hearing aid tips, and much more
						</p>
					</div>
				</div>
				<p className="c-newsletter-unsubscribe">Unsubscribe Anytime</p>
			</div>
		</Layout>
	);
};

export default NewsLetterPage;
